.auto-container {
  display: flex;
}

.flex-container >input {
  height: 30px;
  border: 1px solid #CED4DA;
  border-radius: 2px;
  font-size: inherit;
  color:#403434;
  text-transform: capitalize;
  outline: #0069ff;
}

.flex-container >input:focus {
  border: 1px solid #f1760a;
}

.pos-rel {
  position: relative;
}

.autoContainer {
  position: absolute;
  width: 100%;
  max-height: 280px;
  overflow-y: auto;
  border-width: 0px 1px 1px 1px;
  z-index: 1;
  border-style: solid;
  border-color: #f1760a;
  background-color: whitesmoke;
  right: 0px;
  top: 30px;
}

#option {
  display: flex;
  padding: 5px 5px 5px 5px;
  color:#403434;
  vertical-align: center;
  align-items: center;
  justify-content: space-between;
}

#option,
.auto-container>span {
  font-size: inherit;
  color: inherit;
  text-transform: capitalize;
}

#option:hover {
  background-color: #f1760a;
  color: #FAFBFC;
}

#option.active {
  background-color: #f1760a;
  color: #FAFBFC;
}

.flex-container {
  display: flex;
}

.flex-container > Button {
  border: none;
  background-color: #ffffff;
  color: #403434;
  width: 20px;
  height: 20px;
  position: absolute;
  padding: 0px;
  right: 5px;
  top : 5px;
}

.flex-container > Button:hover {
  background-color: #f1760a;
}

.flex-container > Button:focus {
  background-color: #f1760a;
}

.flex-column {
  flex-direction: column;
}