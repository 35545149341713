/*==================================
* Author        : "ThemeSine"
* Template Name : CarVilla  HTML Template
* Version       : 1.0
==================================== */

/*==================================
font-family: 'Poppins', sans-serif;
font-family: 'Rufina', serif;
==================================== */


/*=========== TABLE OF CONTENTS ===========
1.  General css (Reset code)
2.  Top-area
3.  Welcome-hero
4.  Model-search
5.  Service
6.  New-cars
7.  Featured-cars
8.  Client-saycustomer-login"
9.  Brand
10. Blog
11. Contact
==========================================*/

/*-------------------------------------
		1.General css (Reset code)
--------------------------------------*/




select,
input,
textarea,
button,
.form-control {
    box-shadow: none;
    outline: 0 !important;
}

button {
    background: transparent;
    border: 0;
    font-size: 16px;
    text-transform: capitalize;
}



[placeholder]:focus::-webkit-input-placeholder {
    -webkit-transition: opacity 0.3s 0.3s ease;
    -moz-transition: opacity 0.3s 0.3s ease;
    -ms-transition: opacity 0.3s 0.3s ease;
    -o-transition: opacity 0.3s 0.3s ease;
    transition: opacity 0.3s 0.3s ease;
    opacity: 0;
}

.owl-carousel {
    z-index: 0;
}

/* section-header */
.section-header {
    position: relative;
    text-align: center;
}

.section-header h2 {
    position: relative;
    font-size: 36px;
    font-weight: 500;
    padding-bottom: 35px;
}

.section-header h2:before {
    position: absolute;
    content: "";
    width: 80px;
    height: 2px;
    bottom: 0;
    left: 50%;
    margin-left: -42px;
    background: #4e4ffa;
}

.section-header p {
    color: #444a57;
    text-transform: capitalize;
    margin-bottom: 10px;
}

.section-header h2 span,
.section-header p span {
    text-transform: lowercase;
}

/* section-header */

/*=============Style css=========*/


/*-------------------------------------
        2. Top-area
--------------------------------------*/
nav.navbar.bootsnav {
    background-color: transparent;
    border-bottom: transparent;
}

.wrap-sticky nav.navbar.bootsnav.sticked {
    background-color: #000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
}

/*.navbar-brand*/
.navbar-header a.navbar-brand,
.navbar-header a.navbar-brand:hover,
.navbar-header a.navbar-brand:focus {
    display: inline-block;
    color: #fff;
    font-size: 24px;
    letter-spacing: 3px;
    font-family: 'Rufina', serif;
    font-weight: 700;
    padding: 45px 0px;
    text-transform: uppercase;
}

.navbar-header a.navbar-brand span {
    display: inline-block;
    color: #4e4ffa;
    text-transform: capitalize;
}

.wrap-sticky nav.navbar.bootsnav.sticked .navbar-header a.navbar-brand,
.wrap-sticky nav.navbar.bootsnav.sticked .navbar-header a.navbar-brand:hover,
.wrap-sticky nav.navbar.bootsnav.sticked .navbar-header a.navbar-brand:focus {
    padding: 30px 0px;
}

/*.navbar-brand*/

/*.nav li*/
nav.navbar.bootsnav ul.nav>li>a {
    color: #f5f7fa;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    -ms-transition: 0.3s linear;
    -o-transition: 0.3s linear;
    transition: 0.3s linear;
}

nav.navbar.bootsnav ul.nav>li>a {
    padding: 48px 21px;
}

nav.navbar.bootsnav ul.nav>li>a:hover,
nav.navbar.bootsnav ul.nav>li>a:focus {
    color: #4e4ffa;
}

nav.navbar.bootsnav ul.nav>li.active>a {
    color: #4e4ffa;
}

nav.navbar.bootsnav ul.nav>li.dropdown>a.dropdown-toggle:after {
    content: "";
}

nav.navbar.bootsnav ul.nav>li.dropdown span {
    font-size: 8px;
    margin-left: 15px;
}

.wrap-sticky nav.navbar.bootsnav.sticked ul.nav>li>a {
    padding: 30px 25px;
}

/*.nav li*/

/*.menu-ui-design*/
.menu-ui-design {
    overflow-y: scroll;
    height: 350px;
}

.menu-ui-design::-webkit-scrollbar {
    width: 5px;
}

.menu-ui-design::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 8px #000;
}

.menu-ui-design::-webkit-scrollbar-thumb {
    background-color: #4e4ffa;
}

/*.menu-ui-design*/

/*.navbar-toggle */
nav.navbar.bootsnav .navbar-toggle {
    position: relative;
    background-color: transparent;
    border: 1px solid #4e4ffa;
    padding: 10px;
    top: 0;
}

nav.navbar.bootsnav .navbar-toggle i {
    color: #4e4ffa;
}

/*.navbar-toggle */

ul.nav>li>a {
    color: #eeeff6;
}

/*-------------------------------------
        3.  Welcome-hero
--------------------------------------*/
.welcome-hero {
    position: relative;
    background: url(../../images/welcome-hero/welcome-banner.jpg)no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;
}

.welcome-hero:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(42, 45, 84, .60);
}

.header-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
}

/*.welcome-hero-txt*/
.welcome-hero-txt {
    position: relative;
    text-align: center;
    padding: 290px 0 372px;
    z-index: 99;
}

.welcome-hero-txt h2 {
    font-size: 42px;
    color: #eeeff6;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 45px;
}

.welcome-hero-txt p {
    font-size: 18px;
    text-transform: initial;
    color: #f9f9f9;
    font-weight: 500;
    max-width: 735px;
    margin: 0 auto;
}

.welcome-btn {
    display: inline-block;
    width: 230px;
    height: 60px;
    background: #4e4ffa;
    color: #fff;
    border-radius: 3px;
    margin-top: 55px;
    -webkit-transition: .3s linear;
    -moz-transition: .3s linear;
    -ms-transition: .3s linear;
    -o-transition: .3s linear;
    transition: .3s linear;
}

.welcome-btn:hover {
    background: #0102fa;
}

/*.welcome-hero-txt*/

/*-------------------------------------
        4. Model-Search
--------------------------------------*/
.model-search-content .col-sm-12 {
    padding: 0;
}

.model-search-content {
    padding: 40px 0;
    background: #fff;
    box-shadow: 0 10px 40px 0px rgba(38, 40, 64, .2);
    position: absolute;
    bottom: -150px;
    width: 100%;
    border-radius: 3px;
}

/*.single-model-search*/
.single-model-search {
    margin-bottom: 30px;
}

.single-model-search:last-child {
    margin-bottom: 0;
}

.single-model-search h2 {
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 20px;
}

/*model-select-icon*/
.model-select-icon {
    position: relative;
}

.model-select-icon .form-control {
    outline: 0 !important;
    box-shadow: none;
    border: 1px solid #f8f8f8;
    background: #f8f8f8;
    height: 60px;
}

.model-select-icon select {
    font-size: 16px;
    color: #888f9d;
    text-transform: capitalize;
}

.model-select-icon:after {
    position: absolute;
    content: "\f103";
    right: 0px;
    top: 0;
    font-size: 10px;
    color: #888f9d;
    background: #f8f8f8;
    height: 60px;
    line-height: 60px;
    width: 50px;
    text-align: center;
    font-family: "Flaticon";
    pointer-events: none;
    border: transparent;
}

/*model-select-icon*/
.welcome-btn.model-search-btn {
    width: 160px;
    margin-top: 80px;
    margin-left: 53px;
}

/*.single-model-search*/

/*-------------------------------------
        5. Service 
--------------------------------------*/
.service {
    padding: 260px 0 87px;
}

.single-service-item {
    text-align: center;
    padding: 55px 30px 60px;
    border-radius: 3px;
    margin-bottom: 30px;
    border: 1px solid #dadfe9;
    -webkit-transition: .3s linear;
    -moz-transition: .3s linear;
    -ms-transition: .3s linear;
    -o-transition: .3s linear;
    transition: .3s linear;
}

.single-service-icon {
    display: inline-block;
    color: #50616c;
}

.single-service-item h2 a {
    font-size: 20px;
    margin: 35px 0 20px;
}

.single-service-item h2 a span {
    text-transform: lowercase;
}

.single-service-item p {
    padding-bottom: 35px;
    text-transform: initial;
    position: relative;
}

.single-service-item p:before {
    position: absolute;
    content: "";
    width: 20px;
    height: 3px;
    background: #3030ea;
    bottom: 0;
    left: 50%;
    margin-left: -10px;
}

.single-service-icon [class^="flaticon-"]:before,
.single-service-icon[class*=" flaticon-"]:before,
.single-service-icon [class^="flaticon-"]:after,
.single-service-icon [class*=" flaticon-"]:after {
    font-size: 60px;
}

.single-service-item:hover .single-service-icon [class^="flaticon-"]:before,
.single-service-item:hover .single-service-icon [class*=" flaticon-"]:before,
.single-service-item:hover .single-service-icon [class^="flaticon-"]:after,
.single-service-item:hover .single-service-icon [class*=" flaticon-"]:after {
    color: #fff;
}


.single-service-item:hover h2 a,
.single-service-item:hover p {
    color: #fff;
}

.single-service-item:hover p:before {
    background: #fff;
}

.single-service-item:hover {
    box-shadow: 0 12px 30px 0px rgba(0, 1, 193, .2);
    background: #4e4ffa;
    border: 1px solid #4e4ffa;
}


/*-------------------------------------
        6. New-cars
--------------------------------------*/
.new-cars {
    background: #f8f9fb;
    padding: 50px 0 20px;
}

.new-cars-content {
    padding-top: 96px;
}

/*.new-cars-txt*/
.new-cars-txt {
    margin-left: 29px;
    margin-bottom: 30px;
}

.new-cars-txt h2 {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 26px;
}


.new-cars-txt p {
    text-align: justify;
    margin: 0;
    color: #818998;
    font-size: 16px;
    line-height: 1.8;
    text-transform: initial;
}


.new-cars-txt h2 a span {
    text-transform: uppercase;
}

.new-cars-para2 {
    margin-top: 30px;
}

.welcome-btn.new-cars-btn {
    width: 176px;
    margin-top: 32px;
}

/*.new-cars-txt*/

/*.owl-dots */
.new-cars .owl-theme .owl-dots .owl-dot span {
    width: 8px;
    height: 8px;
    margin: 0px 4px;
    background: transparent;
    border: 1px solid #6a7781;
    border-radius: 50%;
}

.new-cars .owl-theme .owl-dots .owl-dot.active span,
.new-cars .owl-theme .owl-dots .owl-dot:hover span {
    background: #6a7781;
}

.new-cars .owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 62px;
}

/*.owl-dots */

/*-------------------------------------
        7. Featured-cars
--------------------------------------*/
.featured-cars {
    padding: 112px 0 100px;
}

.featured-cars-content {
    padding-top: 96px;
}

/*.single-featured-cars*/
.featured-img-box {
    border: 1px solid #dadfe9;
}

.featured-cars-img {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    height: 220px;
    border-bottom: 1px solid #dadfe9;
}

.featured-model-info {
    padding: 12px 7px;
}

.featured-model-info p {
    font-size: 12px;
    color: #8c92a0;
    text-transform: capitalize;
}

.featured-mi-span {
    display: inline-block;
    margin: 0 10px;
}

.featured-hp-span {
    display: inline-block;
    margin-right: 10px;
}

.featured-cars-txt {
    margin: 21px 0 47px;
}

.featured-cars-txt h2 a {
    font-size: 16px;
    margin-bottom: 15px;
}

.featured-cars-txt h2 a span {
    text-transform: uppercase;
}

.featured-cars-txt h3 {
    margin-bottom: 10px;
}

.featured-cars-txt h3,
.featured-cars-txt p {
    font-size: 13px;
}

/*.single-featured-cars*/

/*-------------------------------------       
        8. Clients-say 
--------------------------------------*/
.clients-say {
    padding: 20px 0 20px;
    background: #f8f9fb;
}

/*single-testimonial-box */
.single-testimonial-box {
    padding: 50px 30px 57px;
    text-align: center;
    border: 1px solid #dadfe9;
    overflow-x: hidden;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

/*testimonial-description*/
.clients-say .testimonial-carousel .col-sm-3,
.clients-say .testimonial-carousel .col-xs-12 {
    width: 100%;
    height: auto;
}

/* testimonial-info */
.testimonial-img {
    margin-right: 5px;
    border-radius: 50%;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.testimonial-person h2 a,
.testimonial-person h4 {
    font-size: 16px;
    color: #5e6778;
    font-weight: 600;
}

.testimonial-person h4 {
    margin-top: 10px;
}

/* testimonial-info */


/* testimonial-comment */
.testimonial-comment p {
    font-size: 16px;
    color: #5e6778;
    margin-top: 30px;
    margin-bottom: 25px;
}

/* testimonial-comment */


/*testimonial-description*/
.single-testimonial-box:hover h2 a,
.single-testimonial-box:hover h4,
.single-testimonial-box:hover p {
    color: #fff;
}

.single-testimonial-box:hover {
    background: #4e4ffa;
    border: 1px solid #4e4ffa;
    box-shadow: 0 12px 30px 0px rgba(0, 1, 193, .2);
}

/*owl carousel*/
.clients-say .owl-carousel .owl-stage {
    position: relative;
    padding: 100px 0 40px;
}

.clients-say .owl-carousel .owl-item img {
    display: block;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 0 auto;
}

/*owl carousel*/

/*-------------------------------------
        9. Brand
--------------------------------------*/
.brand {
    padding: 120px 0;
}

.brand-area {
    position: relative;
}

.brand-area .item {
    padding: 0 40px 0;
}

.brand .brand-area .owl-carousel .owl-item img {
    display: block;
    width: 100%;
    height: 72px;
    border-radius: 0;
}

/*-------------------------------------
        10. Blog
--------------------------------------*/

/*-------------------------------------
        12. Contact
--------------------------------------*/
.contact {
    background: #2a2d54;
}

.footer-top {
    padding: 50px 0 30px 80px;
}

/*.footer-logo*/
.footer-logo a,
.footer-logo a:hover,
.footer-logo a:focus {
    display: inline-block;
    color: #fff;
    font-size: 24px;
    letter-spacing: 3px;
    font-family: 'Rufina', serif;
    font-weight: 700;
    text-transform: uppercase;
}

.footer-logo label,
.footer-logo label:hover,
.footer-logo label:focus {
    display: inline-block;
    color: #fff;
    font-size: 24px;
    letter-spacing: 3px;
    font-family: 'Rufina', serif;
    font-weight: 700;
    text-transform: uppercase;
}

.single-footer-widget p {
    font-size: 14px;
    color: #eeeff6;
    max-width: 300px;
    margin: 40px 0 20px;
}

.footer-contact p {
    margin: 0;
    color: #d1d7e9;
}

/*.footer-logo*/

.single-footer-widget {
    margin-bottom: 40px;
}

.single-footer-widget h2 {
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 43px;
}

.single-footer-widget ul li {
    display: inherit;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}

.single-footer-widget ul li:hover {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
}

.single-footer-widget ul li a {
    font-size: 14px;
    color: #cfd0e3;
    font-weight: 300;
    padding-bottom: 13px;
}

.single-footer-widget ul li a span {
    text-transform: lowercase;
}

.footer-newsletter p {
    color: #aeafc2;
    font-size: 14px;
    font-weight: 300;
    margin: 0 0 15px;
}

/*hm-foot-email*/
.hm-foot-email {
    position: relative;
}

.foot-email-box .form-control {
    background: #26294d;
    display: block;
    padding: 15px 20px;
    outline: 0 !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .2);
    border: 0;
}

.foot-email-box input[type="text"] {
    font-size: 12px;
    color: #666c81;
    font-weight: 300;
}

.foot-email-subscribe {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    background: transparent;
    color: #666c81;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.foot-email-box .form-control:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, .4);
}

/*hm-foot-email*/
/*.footer-copyright */
.footer-copyright {
    padding: 20px 0 17px;
    border-top: 1px solid #434859;
}

.footer-copyright p,
.footer-copyright p a {
    color: #a8a9bf;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
}

.footer-social {
    text-align: right;
}

.footer-social a i {
    color: #fff;
    opacity: .40;
    font-size: 14px;
    margin-left: 16px;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.footer-social i:hover {
    opacity: .70;
}

/*.footer-copyright */

/*===============================
    Scroll Top
===============================*/
#scroll-Top .return-to-top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    display: none;
    width: 40px;
    line-height: 40px;
    height: 40px;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    color: #fff;
    background: #4e4ffa;
    border: 1px solid #4e4ffa;
    border-radius: 50%;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    z-index: 2;
}

#scroll-Top .return-to-top:hover {
    background: #fff;
    color: #4e4ffa;
    border: 1px solid #4e4ffa;
}

#scroll-Top .return-to-top i {
    position: relative;
    bottom: 0;

}

#scroll-Top .return-to-top i {
    position: relative;
    animation-name: example;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-duration: 1s;
}


.enquiry-box {
    height: 70px;
}

.enquiry {
    padding: 0px 0 50px;
    background: #f8f9fb;
    width: 100%;


}

.enquiry-enclose {
    position: absolute;
    margin-top: 10px;
    width: 60%;
    margin-left: 140px;
}

.enquiry-box .form-control {
    background: #dadbe1;
    display: block;
    padding: 15px 20px;
    outline: 0 !important;
    border: 1px solid black;
}

.enquiry button {
    border: 1px solid black;
    border-radius: 2px;
    color: white;
    background-color: #26294d;
    padding: 6px 15px 6px 15px;
}

.enquiry #container {
    border: 1px solid #DBE0E9;
    height: 220px;
}



.cutomer-login {
    font-family: 'Poppins', sans-serif;
    position: absolute;
    width: 22rem;
    padding: 10px 20px 10px 20px;
    top: 110px;
    right: 30px;
    background-color: #7C1B32;
    z-index: 110;
}


.customerIdLabel {
    font-size: 15px;
    font-weight: 200;
    color: #e7e3e3;
    font-weight: 300;
}


.customerIdErr {
    color: #ff0000;
    padding-left: 5px;
    font-size: 12px;
}

.customer-login-btn-box button {
    border-radius: 2px;
    color: #e7e3e3;
    font-size: 12px;
    background-color: #26294d;
    padding: 6px 15px 6px 15px;
}

.customer-login-btn-box button:hover {
    border-radius: 2px;
    color: #e7e3e3;
    font-size: 12px;
    background-color: #1f2031;
    padding: 6px 15px 6px 15px;
}

.customer-login-btn-box {
    margin-top: 10px;
    float: right;
}

.borderItem {
    border: 1px solid #07332b;
}

.float-left {
    float: left
}

.float-right {
    float: right
}

.pricing{   
    right:20px;
    background: #f8f9fb;
}

.pricing #container {
    border: 1px solid #DBE0E9;
    height: auto;
}

div.mytable {
    display: table;
    width: 100%;
}

div.mytable > div {
    display: table-row;
    width: 100%;
}
div.mytable > div > div {
    display: table-cell;
    width: 20%;
    padding:1em;
}
div.mytable > div > div > label {
    color :#7C1B32;
    font-size: 26px;
    margin-left: 100px;
    font-weight: bold;
    text-transform: uppercase;
}

div.mytable > div > div > span {
    color : #00B050;
    font-size: 20px;
    margin-left: 50px;
    font-weight: bold;
}

.hightlight {
    color: #262626;
}

.pricingConentBorderTop {
    border-top: 1px  solid #4e4ffa;
}

.pricingConentBorderRight {
    border-right: 1px  solid #4e4ffa;
}

.pricingConentBorderBottom {
    border-bottom: 1px  solid #4e4ffa;
}

.pricingConentBorderLeft {
    border-left: 1px  solid #4e4ffa;
}


/*========================Thank you=================*/