.info {
  width: 100%;
  background: #fff;
  border-left: 5px solid #E91E63;
}

.wrap {
  width: 100%;
  max-width: 20rem;
  margin: 0 auto;
}

.passwordBox {
  position: relative
}

.login-box {
  background: #fff;
  padding: 1rem;
  border-radius: 6px;
  margin: 1rem;
  border: 1px solid #ddd;
}


.password-trigger {
  position: absolute;
  float: right;
  top: 20%;
  right: 1rem;
  color: #666;
  z-index: 1;
  user-select: none;
}