.forgotpwd {
  background: #cddacf;
  background-image: none;
  float: left;
  height: 100vh;
}

.forgotpwd h2 {
  color: #343A40;
  margin-left: 30%;
  width: fit-content;
  padding: 10px;
  text-decoration: underline;

}

.forgotpwdBox {
  background-color: #ffffff;
  border-radius: 5px;
  margin-left: 400px;
  margin-top: 200px;
  border: 1px solid #8c9e8f;
}

.forgotpwdComp {
  padding: 5px 5px 2px 65px;

}

.forgotpwdComp label {
  font-size: 15px;
  font-weight: lighter;
  color: #16191d;
  width: 100%;
}

.forgotpwdComp input {
  width: 80%;
  outline: 0;
  border-width: 0 0 2px;
  border-color: #1bc53b;
  color: #343A40;
  height: 30px;
  padding-left: 5px;
  font-size: small;
}

.forgotpwdComp input:hover {
  border-width: 0 0 2px;
  border-color: #78c486;
}

.forgotpwdComp input:focus {
  border-width: 0 0 2px;
  border-color: #343A40;
}


.forgotpwdComp button {
  width: 80%;
  border-radius: 2px solid #3949AC;
  background-color: #3949AC;
  border-width: 0px;
  color: #ffffff;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 700;
}

.forgotpwdComp button:hover {
  width: 80%;
  border-radius: 2px solid #3F51B5;
  background-color: #3F51B5;
  border-width: 0px;
  color: #ffffff;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 700;
}