.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.credit-form {
  max-width: 100%;
  margin: auto;
}

html {
  font-family: 'lucida grande', tahoma, verdana, arial, sans-serif;
  font-size: 12px;
  color: #403434;
}

.errorMsg {
  color: red
}

.message {
  max-width: 50%;
  margin: auto;
}

.sales-form {
  max-width: 80%;
  margin: auto;
}

.buttonW {
  width: "100px";
  height: "30px"
}

.wrapper {
  height: max-content;
  background-color: #cccccc;
}

.vert {
  position: absolute;
  right: 0;
  left: 0
}

.first {
  top: 0;
  height: auto;
}

.second {
  top: 20px;
  height: auto;
}

.edit-form {
  max-width: 300px;
  margin: auto;
}

.info-page {
  margin-left: 350px
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
}

* {
  box-sizing: border-box;
}

.clear-btn {
  background-color: #17A2B8;
}

.clear-btn:hover {
  background-color: #138496;
}

.btn {
  padding: 5px 10px 5px 10px;
  font-size: 12px;
  font-weight: 475;
  border-radius: 3px;
  border: 0;
  color: #fdfffe;
  text-transform: uppercase;
}

.btn:hover {
  color: #fdfffe;
}

.delete-btn {
  background-color: #C82333;
}

.delete-btn:hover {
  background-color: #DC3545;
}

.delete-btn-editmode {
  background-color: #C82333;
}

.delete-btn-editmode:hover {
  background-color: #DC3545;
}

.add-btn {
  background-color: #213093;
}

.add-btn:hover {
  background-color: #323a6c;
}

.update-btn {
  background-color: #218838;
}

.update-btn:hover {
  background-color: #28A745;
}

.download-btn {
  background-color: #18584c;
}

.download-btn:hover {
  background-color: #07332b;
}

.back-btn {
  background-color: #F50057;
}


.back-btn:hover {
  background-color: #CB0F61;
}

.next-btn {
  background-color: #3F51B5;
}

.next-btn:hover {
  background-color: #3140A0;
}

.borderItem {
  border: 1px solid #07332b;
}

.background-transparent {
  background-color: transparent;
}

.float-left {
  float: left
}

.float-right {
  float: right
}

.fm-nav {
  background-color: #0f0803;
  text-decoration: dotted;
}

.mr-auto .show>.nav-link {
  color: #ec8327;
}

.adminForm {
  float: left;
  padding-top: 10px;
}

.adminFormReducedPadding {
  float: left;
  padding-top: 9px;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border-radius: 2px;
  color: white;
  background-color: #ec8327;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.upload-box {
  border-radius: 2px;
  border-style: dashed;
  border-color: #ec8327;
}

.newBox-enter {
  opacity: 0;
  transform: scale(0.9);
}

.newBox-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.newBox-exit {
  opacity: 1;
}

.newBox-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}


.addNewHeading:hover {
  color: #212529;
  background-color: #fffaf6;
  padding: 2px;
  font-size: 12px;
  text-decoration: none;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 2px solid #01b007;
}

.addNewHeading {
  color: #212529;
  padding: 2px;
  text-decoration: none;
  font-size: 12px;
  background-color: #f9fdf9;
  border: 1px solid #4caf50;
}

.editHeading {
  color: #212529;
  padding: 2px;
  text-decoration: none;
  font-size: 12px;
  background-color: #FFC107;
  border: 1px solid #FFC107;
}

.editHeading:hover {
  color: #212529;
  background-color: #fffaf6;
  padding: 2px;
  font-size: 12px;
  text-decoration: none;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 2px solid #FFC107;
}

.cancelHeading {
  color: #212529;
  padding: 2px;
  text-decoration: none;
  font-size: 12px;
  background-color: #dfdddd;
  border: 1px solid #dfdddd;
}

.cancelHeading:hover {
  color: #212529;
  background-color: #fffaf6;
  padding: 2px;
  font-size: 12px;
  text-decoration: none;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 2px solid #212529;
}

.addNewBox {
  color: #343A40;
  background-color: #f3f3f3;
  border: 1px solid #343A40;
  border-radius: 4px;
}

.addNewClose {
  color: #fdfdf9;
  background-color: #3e403e;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border: 1px solid #3e403e;
}

.addNewClose:hover {
  color: #3e403e;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #fdfdf9;
  border: 1px solid #3e403e;
}

h4 {
  color: #343A40
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #343A40;
  background-color:  #fffafa;
}

/*alertdialog*/

.alertDialogBtn {
  padding: 2px 15px 2px 15px;
  border-radius: 2px;
  font-size: 15px;
}

.alertYes {
  background-color: #C82333;
  color: white;
  border: 0;
}

.alertYes:hover {
  background-color: #DC3545;
  color: white;
  border: 0;
}


.alertNo {
  background-color: #646363;
  color: white;
  border: 0;
}

.alertNo:hover {
  background-color: #817f7f;
  color: white;
  border: 0;
}

/*alertdialog*/

/*model*/



.cancel {
  color: #646363;
  border: 0;
}

.Model-show-success {
  position: absolute;
  z-index: 1;
  width: 250px;
  height: 30px;
  border: 1px solid #08430f;
  border-radius: 2px;
  color: #08430f;
  visibility: visible
}

.Model-show-success div {
  color: #08430f;
  width: 100%;
  padding: 5px;
  float: right;
  font-weight: bold;
}


.Model-show-error {
  position: absolute;
  z-index: 1;
  width: 250px;
  height: 30px;
  border: 1px solid #d40015;
  border-radius: 2px;
  color: #d40015;
  visibility: visible
}

.Model-show-error div {
  color: #d40015;
  width: 100%;
  padding: 5px;
  float: right;
  font-weight: bold;
}


.Model-hide {
  position: absolute;
  z-index: 1;
  visibility: hidden
}

/*model*/

/*roheader*/

.roheader {
  width: 70%;
  font-size: 12px;
  color: #4CAF50;
}


.fmanLogo {
  background-image: url('/src/assets/logo/fm-logo-man.png');
}


.adminLink {
  text-decoration: none;
  color: #ec8327;
}

.adminLink:hover {
  text-decoration: none;
  color: #ee994e;
  cursor: pointer
}

.navbar {
  background-color: #07332b;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
  height: 30px;
}


.infoContentSuccess {
  color:#01b007
}

.actionSuccess-page {
  margin-left: 300px
}