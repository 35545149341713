.fmanTable {
  border-collapse: collapse;
  margin-top: 10px;
  width: 100%;
}

.fmanTable td,
#fuelsales th {
  border: 1px solid #ddd;
  padding: 8px;
}

.fmanTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.fmanTable tr:hover {
  background-color: #ddd;
}

.fmanTable caption {
  text-align: start;
  font-size: medium;
  font-weight: bold;
  align-items: left;
}

.norecord p{
  color: #2236b5;
  margin-left: 40%;
}

.fmanTable Button:focus{
  outline: none !important;
  background-color: none !important;
}

.fmanTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4CAF50;
  color: white;
}

.fmanTable td {
  text-align: left;
}

.fmanTable thead tr{
  border: 1px solid #4CAF50;
}

.fm-ClearFilters {
  color: #2236b5;
  font-weight: bold;
  padding: 2px;
  text-decoration: none;
  font-size: 12px;
  background-color:  #ffffff;
}

.fm-ClearFilters:hover {
  color: #1c2b8d;
  background-color: #dbdff7;
  padding: 2px;
  font-size: 12px;
  text-decoration: none;
  border-top : 0px;
  border-left : 0px;
  border-right : 0px;
  border-bottom : 2px solid #1c2b8d;
}

#expandRow.coolclass {
  max-height: 100%;
}

#expandRow {
  max-height: 0%;
  transition-delay: 2s;
  overflow: hidden;
}

#checkbox-container {
  margin-top: 12px;
  padding-top: 12px;
  padding-bottom: 20px;
  padding-left: 0px;
  margin-left: 0px;
}

.pagignationHighLt {
  background-color: #28A745;
}

.pagination{
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 0px;
  border: 1px solid #ddd; /* Gray */
}

.pg-border-left-style{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pg-border-right-style{
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pg-color {
  color: black
}

.pg-color-selected {
  color: white
}

.input-style {
  border: 1px solid #ddd;
  border-radius: 4px;
}

.pg-selected {
  background-color:  #4CAF50;
  border: 1px solid #4CAF50;
}


.center {
  margin: auto;
  width: 70%;
  padding: 10px;
}

.custom-select {
  padding: 0px;
  width: 70px;
  height: 25px;
}
.custom-select select{
  border: 0px;
  width: 100%;
  height: 100%;
}

.fmanTable iconBtn:focus {
  background-color: transparent;
  color: transparent;
}
.fmanTable iconBtn:hover {
  background-color: transparent;
  color: transparent;
}

/*ETable css*/

.clearActionHidden {
  position: absolute;
  visibility: hidden;
  z-index: 1
}

.clearActionVisible {
  position: absolute;
  visibility: visible;
  background-color: #C82333;
  border-radius: 0px 5px 5px 0px;
  color:  #FFFFFF;
  z-index: 1
}

.clearActionVisible:hover {
  position: absolute;
  visibility: visible;
  background-color: #DC3545;
  border-radius: 0px 5px 5px 0px;
  color:  #FFFFFF;
  z-index: 1
}
/*ETable css*/

